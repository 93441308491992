@charset "utf-8";
/* ==============================
   Small desktop
   ============================== */

@media only screen and (max-width: 1366px) {


}

@media only screen and (max-width: 1200px) {

}


@media only screen and (max-width: 1199px) {

  /*Category cards*/
  .e-card-item-wrap {
    margin-bottom: 20px;
  }
  .e-card-item-wrap img{
    width: 100%;
  }
  .e-h2-stlye1-wrap h2{
    font-size: 18px;
    margin-top: -8px;
  }
  .e-card-four-column p{
    padding: 10px;
    font-size: 11px;
  }
  .e-card-four-style-1 p{
    margin-bottom: 0px;
  }
  .e-card-four-style-1 img{
    padding-bottom: 5px;
  }
  .e-card-four-style-3 img{
    padding-bottom: 20px;
  }
  .e-card-four-style-3 p{
    bottom: 0px;
  }
  .e-card-four-style-5 img{
    padding-bottom: 10px;
  }
  .e-card-four-style-5 p{
    bottom: 0px;
  }
  .e-card-four-style-7 img{
    padding-bottom: 20px;
  }
  .e-card-four-style-7 p{
    bottom: 20px;
    padding: 10px 0px 10px 20px;
  }
  .e-card-four-style-8 img{
    padding-bottom: 5px;
  }
  .e-card-four-style-8 p{
    bottom: 0px;
  }
  .e-card-four-style-9 img{
    padding-bottom: 20px;
  }
  .e-card-four-style-9 p{
    bottom: 0px;
  }
  .e-card-four-style-10 img{
    padding-bottom: 20px;
  }
  .e-card-four-style-10 p{
    bottom: 20px;
  }
  .e-card-four-style-11 img{
    padding-bottom: 20px;
  }
  .e-card-four-style-11 p{
    bottom: 20px;
  }
  .e-card-four-style-12 img{
    padding-bottom: 10px;
  }
  .e-card-four-style-12 p{
    bottom: 0px;
  }
  .e-card-four-style-13 img{
    padding-bottom: 10px;
  }
  .e-card-four-style-13 p{
    bottom: 0px;
  }

  /*Local Market Strp*/
  .e-local-strip-wrap img{
    width: 100%;
  }
  .e-localstrip-wrap{
    margin-bottom: 0 !important;
  }


  /*Section banners*/
  .e-hero-banner-wrap .slick-dots{
    bottom: 4px !important;
    padding-right: 30px;
  }
  .e-hero-banner-wrap .slick-dots li{
    margin: 0;
  }
  .e-hero-banner-wrap .slick-dots li button:before{
    font-size: 10px;
  }

  /*Local-Product detail*/
  .e-before {
      left: 31.4%;
  }
  .e-after {
    position: absolute;
    left: 67%;
  }
  .e-product-detail h4 {
    font-size: 20px;
    line-height: 120%;
  }
  /*---Local Profile--*/
  .e-profile-address {
    margin-left: 20px;
  }
  .e-location-modal .modal-dialog{
    min-width: 720px;
  }
  .e-image-viewer .modal-dialog{
    min-width: 990px;
  }



}

/* ==============================
   Tablet landskape
   ============================== */
@media only screen and (max-width: 1024px) {
.e-favourite-stores .slick-prev{
  left: 0 !important;
}
.e-favourite-stores .slick-next{
  right: 0 !important;
}
}

@media only screen and (max-width: 992px) {
  /*Common*/
  .e-no-p{
    padding: 0 !important;
  }
  .e-no-pb{
    padding-bottom: 0!important;
  }
  .e-no-mb{
    margin-bottom: 0!important;
  }
  .e-pb-15{
    padding-bottom: 15px!important;
  }
  .e-mb-15{
    margin-bottom: 15px!important;
  }
  .e-m-d-block{
    display: block !important;
  }
  .e-main-content{
    margin-bottom: 50px;
    margin-top: 0px;
  }
  /* .e-header-search-btn{
    border-radius: 0;
    border: none;
  } */
  .e-local-strip-wrap{
    margin-top: 30px;
  }

  /*Category*/
  .e-five-banner-wrap .col-10{
    margin-bottom: 30px;
  }
  .e-three-banner-wrap img{
    margin-bottom: 30px;
  }
  .e-section-bg-half{
    bottom: 15px;
    display: none;
  }
  /*local-product-detail*/
  .e-before {
      left: 24.4%;
  }
  .e-after {
    position: absolute;
    left: 73%;
  }
  .e-product-detail h4 {
    font-size: 20px;
    line-height: 120%;
  }
  /*Modal-Login*/
  .e-login-modal .modal-dialog {
    max-width: 590px!important;
}
.e-image-viewer .modal-dialog{
  min-width: 750px;
}
.e-mega-header .e-mega-header-ad{
  height: 300px;
  object-position: top;
}
.e-profile-wrap p{
  font-size: 12px;
}
}

/* ==============================
   Tablet Portrait
   ============================== */
@media only screen and (max-width: 768px) {

  /*Common*/
  .e-mob-pl-5{
    padding-left: 5px;
  }
  .e-mob-pr-5{
    padding-right: 5px;
  }

  .e-mob-p-5{
    padding: 5px;
  }
  .e-mob-p-3{
    padding: 3px;
  }
  .e-fav-card .e-card-wrap {
    position: relative !important;
    width: 100%;
    top: 20%;
    right: 0;
  }
  .e-fav-card{
    padding-bottom: 32px;
  }

  .e-fav-card .e-fav-close{
    top: 60px;
    z-index: 1;
  }
  /* Recent Viewed*/
  .e-recent-viewed-wrap .slick-slide{
    padding-right: 30px;
  }
  .e-recent-viewed-wrap .slick-slide img{
    width: 100%;
  }
  .e-recent-viewed-wrap p{
    font-size: 11px;
  }
  .e-recent-viewed-wrap .slick-next{
    width: 30px !important;
    height: 40px !important;
    right: -10px !important;
    background-size: 9px;
    top: 36% !important;
  }
  .e-recent-viewed-wrap .slick-prev{
    width: 30px !important;
    height: 40px !important;
    background-size: 9px;
    top: 36% !important;
  }

  /*banner*/
  .section-banner-1-wrap img{
    min-height: 200px;
  }
  .e-card-four-style-3 .e-card-item-wrap{
    padding-bottom: 16px;
  }
  .e-card-item-wrap{
    text-align: center;
  }
  .e-card-item-wrap img{
    max-height: 200px;
    /* width: auto !important; */
  }

  /*Category*/
  .e-five-banner-wrap .col-10{
    margin-bottom: 30px;
  }
  .e-three-banner-wrap img{
    margin-bottom: 0;
    height: 100%;
  }
  .e-section-bg-half{
    bottom: 15px;
  }
  .e-section-bg-half.e-buyofmomemt-bg{
    height: 50%;
    bottom: -1px;
    display: inline-block;
  }
  .e-section-bg-half.e-buyofmomemt-bg.e-bg-bottom{
    height: 50%;
    top: -1px;
    bottom: unset;
    display: inline-block;
  }
  .e-five-banner-wrap img{
    margin-bottom: 0;
  }
  .e-5-banner-center img{
    height: 100%;
  }
  .e-brand-logo-heading img{
    width: 120px;
  }

  /*Subcategory*/
  .e-sub-category-page-wrap{
    padding-top: 10px !important;
  }

  /*Deals*/
  .e-card-item-wrap .e-card-3-ad-img{
    width: 100% !important;
    height: auto !important;
  }

  /*Search*/
  .e-serach-section{
    background-size: 100px;
  }

  /*Product*/
  .e-prod-banner .mb-5{
    margin-bottom: 10px !important;
  }
  /*Profile*/
  .e-profile-banner h1 {
    font-size: 42px;
    text-align: center;
  }
  span.e-tabs-item.e-active {
    font-size: 20px;
  }
  span.e-color-inactive {
    font-size: 20px;
  }
  .e-profile h5{
    font-size: 16px;

  }
  .e-main-content{
    margin-bottom: 50px;
    margin-top: 60px;
  }
  /*Contact*/
  .e-contact-banner h1 {
    font-size: 42px;
    text-align: center;
  }
  .e-contact-form h4 {
    font-size: 20px;
  }
  .e-contact-form input.e-text {
    width: 94%;
  }
  .e-subscription-wrap{
    background-position: center bottom !important;
    padding: 60px 0 80px;
    background-size: 120px;
  }
  /*Career*/
  .e-career-banner h1 {
    font-size: 42px;
    text-align: center;
  }
  .e-career h4 {
    font-size: 20px;
  }

  /*About*/
  .e-about-bnr{
    padding: 50px 0;
    background-image: url('../images/about/2751001.png');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 440px;

  }
  .e-product-wrap img{
    height: 350px;
  }
  .e-about-hdr{
    font-size: 40px;
    display: none;
  }
  .e-about-hdr-sm{
    font-size: 40px;
  }
  /* subscription */
  .e-subscription-input-wrap .e-p-btn{
    padding: 16px;
  }
  /*local-product-detail*/
  .e-before {
      left: 16.4%;
  }
  .e-after {
    position: absolute;
    left: 80%;
  }
  .e-product-detail h4 {
    font-size: 20px;
    line-height: 120%;
  }
  .e-reated-products p span {
    font-size: 11px;
  }
  .e-reated-products p {
    font-size: 10px;
  }
  .e-reated-products h6 {
      font-size: 12px;
      line-height: 146%;
  }
   /*Modal-Login*/
  /*.e-login-modal .modal-dialog {
    max-width: 580px!important;
}*/


.e-video-player, .e-hero-banner-wrap .e-video-player{
  height: initial !important;
}
.e-hero-banner-wrap  .slick-list{
  height: 275px;
}
.e-hero-banner-wrap .slick-track{
  height: 275px;
}
.e-hero-banner-wrap .slick-slide div{
  height: 100%;
}
.e-hero-banner-wrap .slick-slide a{
  height: 100%;
}
.e-hero-banner-wrap .slick-slide video{
  height: 275px;
}
/*heading*/
.e-h2-stlye1-wrap h2{
  font-size: 16px;
  line-height: 24px;
}
.e-h2-stlye1-wrap.e-svg-hidden svg{
  visibility: hidden;
}
.e-location-modal .modal-dialog{
  min-width: 560px;
}
.e-image-viewer .modal-dialog{
  min-width: 560px;
}
.e-about-img-container img{
  width: 80%;
}
.e-new-col-label{
  padding: 8px;
  bottom: 12px;
  width: 35%
}
}

/* ==============================
   Phone
   ============================== */
@media only screen and (max-width: 575px) {



  /* Four Column list */
  .e-list-four-column-style1 .col-6{
    margin-bottom: 20px;
  }

  /* subscription */
  .e-subscription-wrap h6{
    padding: 0 30px;
  }
  .e-location-modal .e-location-header{
    background-image: none;
  }
  /* .e-subscription-wrap .e-subscription-input-wrap{
    padding: 15px;
  } */
  .e-subscription-wrap .e-subscription-input-wrap input{
    min-width: 320px;
    font-size: 12px;
    line-height: 185%;
  }
  .e-subscription-wrap .e-header-search::placeholder{
    font-size: 10px;
  }
  .e-subscription-wrap{
    background-position: center bottom !important;
    padding: 60px 0 80px;
    background-size: 120px;
  }
  .e-card-item-wrap img{
    width: 100% !important;
    max-height: initial !important;
  }
  /*Product-detail*/
  .e-product-detail-tab span.e-color-inactive {
    font-size: 15px;
  }
  .e-reated-products h4 {
    font-size: 17px;
  }
  .e-p-btn.e-contact-btn.e-offer-btn {
    padding: 16px 10px;
    font-size: 15px;
  }
  .e-p-btn.e-contact-btn.e-favourite-btn {

    padding: 15px 20px 15px 45px;
    font-size: 15px;
    background-position: 10px 13px;
  }
  .e-product-detail h4 {
    font-size: 17px;
  }
  /*Local profie*/
  .e-name {
    padding: 30px;
    font-size: 44px;
  }
  .e-location-modal .modal-dialog{
    min-width: unset;
    margin-left: 20px;
    margin-right: 20px;
  }
  .e-image-viewer .modal-dialog{
    min-width: 90vw;
  }
  .e-local-profile-content p{
    font-size: 12px;
  }
  .e-login-content-wrap {
    padding: 35px 60px 10px 45px;
  }
  .e-login-modal .e-left-banner{
    min-height: 150px;
  }
  .e-login-img {
    position: absolute;
    width: 138px;
    right: 34px;
    bottom: 8px;
    top: unset;
  }
  .e-login-modal p {
    padding-top: 80px;
    text-align: center !important;
  }
  .e-fav-card .e-card-wrap{
    height: max-content;
  }
  /* .e-hero-banner-wrap img{
    min-height: 260px;
  } */
  .e-about-bnr{
    height: 300px;
  }
  .e-product-wrap img{
    height: 300px;
  }
}

@media only screen and (max-width: 380px) {
  .e-fav-card .e-card-wrap{
    height: max-content;
  }
  .e-wholesale-price-heading svg{
    display: none;
  }
  /* .e-hero-banner-wrap img{
    min-height: 220px;
  } */
  .e-about-bnr{
    height: 200px;
  }
  .e-product-wrap img{
    height: 260px;
  }
}


/* ==============================
   Small Phone
   ============================== */
@media only screen and (max-width: 319px) {


}