@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "[Project and short descp]"
Version:    1.0
Created:    "[Created date and time]"
Use:   "[Project template used for]"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Fonts import, Typography
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA
6. Forms fields
7. Animations

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

1. Fonts import, Typography

-------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*@import url('https://fonts.googleapis.com/css2?family=Philosopher:wght@700&display=swap')*/

body{
	font-family: 'Montserrat', sans-serif;
}




/*------------------------------------------------------------------

2. Colors

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

3. Page loader / .page-loader

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

4. Global styles

-------------------------------------------------------------------*/
body, html{
	overflow-x: hidden;
}
*:focus, *{
	outline: none;
}
a{
	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
a:hover{
	color: #FF4A31 !important;
}
html {
  scroll-behavior: smooth;
}




/*------------------------------------------------------------------

5. Buttons and CTA

-------------------------------------------------------------------*/
.e-p-btn{
	color: #FFFFFF;
	font-weight: 600;
	font-size: 13px;
	line-height: 16px;
	background: #FF4A31;
	border-radius: 16px 0px;
	display: inline-block;
}
.e-inactive{
  opacity: .5;
  cursor: not-allowed !important;
}




/*------------------------------------------------------------------

6. Forms fields

-------------------------------------------------------------------*/






/*------------------------------------------------------------------

7. Icons

-------------------------------------------------------------------*/






/*------------------------------------------------------------------

8. Other

-------------------------------------------------------------------*/

.e-error{
  background-color: #F5C0C0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #D40000;
  font-weight: 500;
  border: 1px solid #D40000;
  text-transform: capitalize;
}
.e-toast{
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 99;
}
.e-toast.success{
  background-image: url('../images/icons/check.svg');
  border-color: #68D868;
  background-color: #E9FFE0;
}
.e-toast.fail{
  background-image: url('../images/icons/cancel.svg');
  border-color: red;
  background-color: #F9E5E5;
}
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}