@charset "utf-8";


/*Header*/
header{
	width: 100%;
	z-index: 1;
}
.e-sec-header{
	background: #000000;
	padding: 4px 0px 4px 0px;
}
.e-nav-header{
	left: calc(50% - 1440px/2 - 0px);
	background: #FFFFFF;
  box-shadow: 0px 6px 15px rgba(210, 210, 210, 0.15);
  padding: 15px;
}
/*.e-location{
	margin-top: 10px;
	padding-left: 20px;
	background-size: 16px !important;
	background-position: 0 0px !important;
	background-image: url("../images/icons/location.svg");
	background-repeat: no-repeat;

	color: #FFFFFF;
	font-size: 14px;
	font-weight: 600;
  cursor: pointer;
  display: inline-block;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}*/
.e-location-img {
  background-image: url("../images/icons/location.svg");
  background-repeat: no-repeat;
  background-size: 16px !important;
  background-position: 22px 14px !important;
  cursor: pointer;
}
.e-location-modal .e-location-header{
  background-image: url("../images/icons/location-black.svg");
  background-repeat: no-repeat;
  background-size: 16px !important;
  background-position: right center !important;
  padding-right: 22px;
}
.e-get-location{
  background-image: url("../images/icons/location-color.svg");
  background-repeat: no-repeat;
  background-size: 12px !important;
  background-position: right center !important;
  padding-right: 16px;
  font-size: 10px;
  font-weight: 600;
  color: #FF4A31;
  cursor: pointer;
}

.e-loc-static-txt{
  color: #fff;
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  padding-left: 8px;
  padding-top: 12px;
}
/*.e-header-location .css-yk16xz-control {
  background-color: #000;
}*/
.e-header-location .css-yk16xz-control, .e-header-location .css-1pahdxg-control {
  background-color: #000;
  border: none;
  box-shadow: none;
}
.e-header-location .css-1pahdxg-control:hover{
  border: none;
}
.e-header-location .css-2b097c-container {
  width: 100%
}
.e-header-location .css-1wa3eu0-placeholder,.e-header-location .css-b8ldur-Input input{
  font-weight: 700!important;
  font-size: 14px !important;
  color: #fff!important;
}
.e-header-location .css-g1d714-ValueContainer,.e-header-location .css-1uccc91-singleValue {
  font-weight: 700;
  font-size: 14px;
  color: #fff!important;
}
.e-header-location .css-1okebmr-indicatorSeparator {
  width: 0px;
}
.e-header-language .css-2b097c-container{
  width: 145px;
}
.e-header-language.e-header-location .css-1uccc91-singleValue{
  font-size: 12px;
  font-weight: 600;
}
.e-location:hover{
	background-image: url("../images/icons/location-active.svg");
	color: #FF4A31;
}
.e-header-switch{
	background: #fff;
	width: 100%;
	padding: 2px;
	border-radius: 30px;
}
.e-header-switch-item{
	text-transform: uppercase;
	color: #000000;
	font-size: 12px;
	line-height: 15px;
	font-weight: 600;

	color: #000000;
	background: #fff;
	padding: 10px;
	border-radius: 30px;
	cursor: pointer;
	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-header-switch-item:hover{
	color: #FF4A31;
}
.e-header-switch-item.e-active{
	color: #fff;
	background: #FF4A31;
}
.e-header-switch-item.e-active:hover{
	color: #fff;
}
.e-sec-header-nav a{
	color: #FFFFFF;
	font-size: 12px;
	font-weight: 600;
	margin-top: 10px;
	display: inline-block;
	padding: 0 15px;
	position: relative;
}
.e-sec-header-nav a:after{
	content: " ";
	width:4px;
	height: 4px;
	border-radius: 50%;
	background: #fff;
	display: inline-block;
	position: absolute;
	right: 0;
	top: 7px;
}
.e-sec-header-nav a:last-child{
	padding-right: 0;
}
.e-sec-header-nav a:last-child:after{
	display: none;
}
.e-nav-header .css-2b097c-container{
	display: inline-block;
	min-width: 200px;
	font-size: 12px;
}
.css-yk16xz-control{
	border: none !important;
	box-shadow: none !important;
}
.e-nav-header .css-1okebmr-indicatorSeparator{
	display: none;
}
.e-nav-header .css-yk16xz-control:hover{
	border: none !important;
}
.e-nav-header .css-1uccc91-singleValue{
	color: #000000;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 600;
}
.e-header-search{
	border-radius: 16px 0px;
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.5);
	box-sizing: border-box;
	padding: 10px;
	min-width: 100%;
  position: relative;
	font-size: 14px;
}
.e-header-search-btn{
  content: " ";
  background-image: url("../images/icons/search.svg");
  background-size: 18px !important;
  background-position: center !important;
  background-repeat: no-repeat;
  width: 50px;
  height: 43px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 15px;
  border-radius:  0 0 16px 0px;

  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.e-header-search-btn.active{
  cursor: pointer;
  background-color: #ed4634;
}
.e-header-search-btn.active:hover{
  opacity: .8;
}
.e-header-search:focus{
	border: 1px solid #FF4A31;
}
.e-header-search::placeholder{
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
	font-size: 12px;
}
.e-search-line{
	width: 1px;
	height: 100%;
	background: #000;
	display: inline-block;
	position: absolute;
	right: 65px;
	/*opacity: .2*/;
}
.e-favourite-icon{
	width: 24px;
	height: 24px;
	display: inline-block;
	background-image: url("../images/icons/favourite.svg");
	background-repeat: no-repeat;
	margin: 10px 15px 0 0;
}
.e-favourite-icon:hover{
	background-image: url("../images/icons/favourite-active.svg");
}
.e-user-icon{
	width: 24px;
	height: 24px;
	display: inline-block;
	background-image: url("../images/icons/user.svg");
	background-repeat: no-repeat;
	margin: 10px 0 0 0;
  cursor: pointer;
}
.e-user-icon:hover{
	background-image: url("../images/icons/user-active.svg");
}
.e-pwa-header .css-tlfecz-indicatorContainer{
	padding-right: 0;
}
.e-pwa-header .css-1uccc91-singleValue{
	font-size: 10px;
}
.e-pwa-header .css-2b097c-container{
	min-width: 160px;
}
.e-pwa-header .css-26l3qy-menu{
	text-align: left;
}
.e-pwa-header img{
	max-width: 100px;
}
.e-pwa-header{
	padding:16px 10px;
  position: fixed;
  top: 0;
  left: 15px;
  width: 100%;
  z-index: 9;
}

.e-sec-head-wrap{
  background: #F2F2F2;
}
.e-sec-head-wrap .e-page-name{
  background: #FF4A31;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  padding: 15px 10px 15px 20px;
  position: relative;
  margin-right: 60px;
}
.e-sec-head-wrap .e-page-name::after{
  position: absolute;
  left: 100%;
  top: 0;
  content: " ";
  display: inline-block;
  width: 0;
  height: 100%;
  border-bottom: 51px solid #FF4A31;
  border-right: 30px solid transparent;
}
.e-sec-head-link{
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  font-style: normal;
  text-transform: capitalize;
  color: #000000;
  padding: 18px 0;
  margin-right: 15px;
  display: inline-block;
}
/*Footer*/
footer{
	background: #FAFAFA;
	padding: 60px 0 30px;

}
footer h6{
	text-align: center;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 13px;
	line-height: 16px;
}
footer a{
	opacity: 0.87;
	color: #000000;
	font-size: 13px;
	line-height: 16px;
}
.e-social-icon{
	width: 20px;
	height: 20px;
	display: inline-block;
}
.e-fb{
	background-image: url("../images/social/fb.svg");
	background-repeat: no-repeat;
}
.e-fb:hover{
	background-image: url("../images/social/fb-active.svg");
}
.e-insta{
	background-image: url("../images/social/instagram.svg");
	background-repeat: no-repeat;
}
.e-insta:hover{
	background-image: url("../images/social/instagram-active.svg");
}
.e-twitter{
	background-image: url("../images/social/twitter.svg");
	background-repeat: no-repeat;
}
.e-twitter:hover{
	background-image: url("../images/social/twitter-active.svg");
}
.e-youtube{
	background-image: url("../images/social/youtube.svg");
	background-repeat: no-repeat;
}
.e-youtube:hover{
	background-image: url("../images/social/youtube-active.svg");
}
.e-linkedin{
	background-image: url("../images/social/linkedin.svg");
	background-repeat: no-repeat;
}
.e-linkedin:hover{
	background-image: url("../images/social/linkedin-active.svg");
}
.e-pin{
  background-image: url("../images/social/pin.svg");
  background-repeat: no-repeat;
}
.e-pin:hover{
  background-image: url("../images/social/pin-active.svg");
  background-repeat: no-repeat;
}
.e-payment-gatway{
	height: 20px;
	width: 30px;
	display: inline-block;
}
.e-visa{
	background-image: url("../images/icons/visa.svg");
	background-repeat: no-repeat;
}
.e-master{
	background-image: url("../images/icons/master.svg");
	background-repeat: no-repeat;
}
.e-rupay{
	background-image: url("../images/icons/rupay.svg");
	background-repeat: no-repeat;
}
.e-copywrite{
	opacity: 0.5;
	text-transform: capitalize;
	font-size: 12px;
	line-height: 15px;
}
.e-copywrite a{
	text-decoration: underline;
}
.e-privacy-wrap a{
	text-decoration: underline;
	margin-right: 20px;
}
.e-pwa-footer{
	position: fixed;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 10px 0 5px;
  z-index: 20;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-pwa-footer-item{
	padding-top: 20px;
	background-repeat: no-repeat;
	background-size: 14px;
	background-position: center top;
	display: inline-block;
	font-weight: 500;
}
.e-pwa-footer-item.e-active{
	color: #FF4A31;
}
.e-pwa-footer-item-online{
	background-image: url("../images/icons/online.svg");
}
.e-pwa-footer-item-local{
	background-image: url("../images/icons/local.svg");
}
.e-pwa-footer-item-search{
	background-image: url("../images/icons/search.svg");
}
.e-pwa-footer-item-more{
	background-image: url("../images/icons/menu.svg");
}

.e-pwa-footer-item-online:hover, .e-pwa-footer-item-online.e-active{
	background-image: url("../images/icons/online-active.svg");
}
.e-pwa-footer-item-local:hover, .e-pwa-footer-item-local.e-active{
	background-image: url("../images/icons/local-active.svg");
}
.e-pwa-footer-item-search:hover, .e-pwa-footer-item-search.e-active{
	background-image: url("../images/icons/search-active.svg");
}
.e-pwa-footer-item-more:hover, .e-pwa-footer-item-more.e-active{
	background-image: url("../images/icons/menu-active.svg");
}


/*PWA More Menu*/
.e-pwa-more-menu-overlay{
	background: rgba(136, 136, 136, 0.6);
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
}
.e-pwa-more-menu{
	position: fixed;
	right: 0;
	top: 0;
	height: 100vh;
	width: 70vw;
	background: #fff;
	padding: 30px 20px;
  z-index: 99;
	/* margin-top: 40px; */
}
.e-pwa-more-menu .e-close-icon{
	max-width: 20px;
}
.e-pwa-more-menu .e-location{
	color: #000;
	background-image: url("../images/icons/location-black.svg");
  background-repeat: no-repeat;
	padding-left: 15px;
  background-size: 12px !important;
  font-size: 12px;
  background-position-y: center;
}
.e-pwa-more-menu-item{
	padding: 7px 0;
}
.e-pwa-more-menu-item a{
	font-size: 14px;
	padding-left: 20px;
	background-repeat: no-repeat;
	background-size: 12px;
	background-position: 0 2px;
}
.e-pwa-more-menu-item.e-active a{
  color: #FF4A31;
}
.e-powered-wrap{
	position: absolute;
	/* bottom: 60px; */
}
.e-pwa-more-menu-item .e-search{
	background-image: url("../images/pwa/search.svg");
}
.e-pwa-more-menu-item .e-login{
	background-image: url("../images/pwa/login.svg");
}
.e-pwa-more-menu-item .e-about{
	background-image: url("../images/pwa/about.svg");
}
.e-pwa-more-menu-item .e-testimonial{
	background-image: url("../images/pwa/testimonial.svg");
}
.e-pwa-more-menu-item .e-online-links{
  background-image: url("../images/icons/online.svg");
}
.e-pwa-more-menu-item .e-local-links{
  background-image: url("../images/icons/local.svg");
}
.e-pwa-more-menu-item .e-faq{
	background-image: url("../images/pwa/faq.svg");
}
.e-pwa-more-menu-item .e-vendor{
	background-image: url("../images/pwa/vendor.svg");
}
.e-pwa-more-menu-item .e-career{
	background-image: url("../images/pwa/career.svg");
}
.e-pwa-more-menu-item .e-advertise{
	background-image: url("../images/pwa/advertise.svg");
}
.e-pwa-more-menu-item .e-help{
	background-image: url("../images/pwa/help.svg");
}
.e-pwa-more-menu-item .e-contact{
	background-image: url("../images/pwa/contact.svg");
}
.e-pwa-more-menu-item .e-language{
	background-image: url("../images/icons/language.svg");
}
.e-pwa-more-menu-item .e-favourite{
	background-image: url("../images/icons/favourite.svg");
}


/*------------------------------------------------

Headings

-------------------------------------------------*/
.e-h2-stlye1-wrap h2{
	display: inline-block;
	margin: 0 30px;
	font-weight: 800;
	font-size: 24px;
	line-height: 40px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}
.e-h2-stlye1-wrap svg{
	margin-top: -10px;
}
.e-h2-stlye1-wrap.e-h2-style-31 svg{
  margin-top: 0;
}

/*Section Logo Heading*/
.e-brand-logo-heading {
	text-align: center;
}
.e-brand-logo-heading img{
	max-width: 500px;
  min-width: 200px;
}


/*------------------------------------------------

Ads

-------------------------------------------------*/

/*Hero Banner*/
.e-hero-banner-wrap img{
  width: 100%;
  object-fit: cover;
}
.e-hero-banner-wrap .slick-dots{
	bottom: 16px !important;
	padding-right: 200px;
	text-align: right;
}
.e-hero-banner-wrap .slick-dots li button:before{
	color: #CECECE;
	opacity: 1;
	font-size: 12px;
}
.e-hero-banner-wrap .slick-dots li.slick-active button:before{
	color: #FF4A31;
	opacity: 1;
}
.e-hero-banner-img-wrapper{
  width: 100%;
  padding-top: 35% !important;
  overflow: hidden;
}
.e-hero-banner-img-wrapper img.e-hero-banner-img{
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width .25s cubic-bezier(.625,.125,.305,.875), height .25s cubic-bezier(.625,.125,.305,.875);
  object-fit: cover;
  object-position: center;
}
.e-hero-banner-img-wrapper img.e-hero-banner-img:hover{
  width: 105%;
  height: 105%;
}

/*Four Column list ads - Style 1*/
.e-list-four-column-style1 img{
	border-radius: 30px 0  30px 0;
	width: 100%;
	height: auto;
	object-fit: cover;
}
.e-list-four-column-style1 p{
	position: absolute;
	margin-bottom: 0;
	border-radius: 30px 0  30px 0;
	bottom: 0;
	background: #000;
	width: 100%;
	color: #fff;
	padding: 15px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
}

/*Section banner style 1 */
.section-banner-1-wrap img{
	width: 100%;
	object-fit: cover;
	border-radius: 10px;
  max-height: 400px;
}
.e-section-banner-2-style-2 img{
	border-radius: 4px 100px;
}

/*Three Fourth Banner With Category */
.e-ThreeFourthCategory-wrap img{
	width: 100%;
	object-fit: cover;
	margin-bottom: 30px;
	border-radius: 5px;
}
.e-ThreeFourthCategory-wrap a:last-child img{
	margin-bottom: 0;
}

/*Three Banner Section */
.e-three-banner-wrap img{
	width: 100%;
	object-fit: cover;
	/*border-radius: 5px 100px 5px 5px;*/
}

/*Five Banner Section */
.e-five-banner-wrap img{
	width: 100%;
	object-fit: cover;
	margin-bottom: 30px;
}
.e-five-banner-wrap a:last-child img{
	margin-bottom: 0;
}

.e-banner-wrap{
  transition: -webkit-transform .25s cubic-bezier(.625,.125,.305,.875);
  transition: transform .25s cubic-bezier(.625,.125,.305,.875);
  transition: transform .25s cubic-bezier(.625,.125,.305,.875),-webkit-transform .25s cubic-bezier(.625,.125,.305,.875);
}
.e-banner-wrap:hover{
  -webkit-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
}
.e-card-item-hover{
  object-fit: cover;
}
.e-card-item-hover{
  transition: -webkit-transform .25s cubic-bezier(.625,.125,.305,.875);
  transition: transform .25s cubic-bezier(.625,.125,.305,.875);
  transition: transform .25s cubic-bezier(.625,.125,.305,.875),-webkit-transform .25s cubic-bezier(.625,.125,.305,.875);
}
.e-card-item-hover:hover{
  -webkit-transform: scale(.97);
  -ms-transform: scale(.97);
  transform: scale(.97);
}
.e-page-loader, .e-page-nothing, .e-work-progess{
  height: 90vh;
  width: 100vw;
  background-position: center 200px;
  background-image: url("../images/loader/page-loader.gif") !important;
  background-repeat: no-repeat !important;
  background-size: 200px;
  padding-top: 265px;
  font-size: 14px;
  color: #7d7d7d;
}
.e-page-nothing{
  background-image: url("../images/loader/nothing.jpg") !important;
  background-size: 400px;
  padding-top: 350px;
  background-position: center 100px;
}
.e-lazy-loading{
  width: 300px;
  height: 50px;
  display: inline-block;
  background-position: center;
  background-image: url("../images/loader/page-loader.gif") !important;
  background-repeat: no-repeat !important;
  background-size: 200px;
}
.e-work-progess{
  background-image: url("../images/loader/work-progress.jpg") !important;
  background-size: 320px;
  padding: 340px 40px 40px 40px;
  background-position: center 100px;
}
.e-btn-loader{
  width: 240px;
  height: 50px;
  display: inline-block;
  background-image: url("../images/loader/page-loader.gif") !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
/*------------------------------------------------

Category List

-------------------------------------------------*/

/*Four Column card list*/
.e-card-four-column{
	z-index: 1;
}
.e-card-four-column p{
	position: absolute;
	margin-bottom: 0;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 2px;
	bottom: 0;
	background: #000;
	width: 100%;
	color: #fff;
	padding: 15px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
  transform: translateY(50%);
}
.e-card-four-style-2 p{
	width: auto;
  padding: 13px 20px;
  top: 0;
  bottom: initial;
  border-radius: 2px 0 4px 0;
}
.e-card-four-style-3 p{
	bottom: -40px;
}
.e-card-four-style-3 .e-card-item-wrap{
  padding-bottom: 50px;
}
.e-card-four-style-4 p{
	bottom: 4px;
	position: relative;
}
.e-card-four-style-5 p{
	border-radius: 30px 0 30px 0;
}
.e-card-four-style-6 p{
	border-radius: 30px 30px 0 0;
}
.e-card-four-style-6 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-7 p{
	background: linear-gradient(-45deg, #f1f1f1 80%,  transparent 50%);
	box-shadow: none;
	margin-bottom: -20px;
}
.e-card-four-style-7 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-8 p{
	position: relative;
	border-radius: 30px;
	bottom: -15px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	padding: 12px;
	font-weight: 700;
}
.e-card-four-style-8 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-9 p{
  bottom: -26px;
}
.e-card-four-style-9 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-10 p{
	box-shadow: none;
	margin-bottom: -20px;
}
.e-card-four-style-10 .e-card-item-wrap{
  padding-bottom: 30px;
}
.e-card-four-style-11 p{
	box-shadow: none;
	margin-bottom: -20px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-card-four-style-11 .e-card-item-wrap{
  padding-bottom: 30px;
}
.e-card-four-style-12 p{
	border: 2px solid #000000;
	padding: 12px;
	bottom: -15px;
	position: relative;
}
.e-card-four-style-12 .e-card-item-wrap{
  padding-bottom: 15px;
}
.e-card-four-style-13 p{
	bottom: -50px;
	box-shadow: none;
	font-weight: 700;
}
.e-card-four-style-13 .e-card-item-wrap{
  padding-bottom: 50px;
}
.e-card-four-style-13 .e-card-item-wrap img{
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
}
.e-card-four-style-14 p{
	bottom: 0;
	font-weight: 700;
	border-radius: 30px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	padding: 15px;
}
.e-card-four-style-14 .e-card-item-wrap{
  padding-bottom: 15px;
}
.e-card-four-style-15 p{
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-card-four-style-15 .e-card-item-wrap{
  padding-bottom: 15px;
}

.e-card-four-style-16 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: -60px;
}
.e-card-four-style-16 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-16 img{
	border-radius: 100px 4px;
}

.e-card-four-style-17 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-17 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-17 .e-card-item-wrap img{
  border-radius: 50%;
  width: 90% !important;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.e-card-four-style-17 img:hover{
  -webkit-transform: scale(.97) translateX(-50%) !important;
  -ms-transform: scale(.97) translateX(-50%) !important;
  transform: scale(.97) translateX(-50%) !important;
}

.e-card-four-style-18 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-18 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-18 .e-card-item-wrap img{
  border-radius: 200px 0px 200px 200px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-18 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-19 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-19 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-19 .e-card-item-wrap img{
  border-radius: 24px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
}
.e-card-four-style-19 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-20 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -50px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-20 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-20 .e-card-item-wrap img{
  border-radius: 48px;
}

.e-card-four-style-21 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-21 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-21 .e-card-item-wrap img{
  border-radius: 48px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 90% !important;
}
.e-card-four-style-21 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-22 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-22 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-22 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 76% !important;
}
.e-card-four-style-22 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-23 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-23 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-23 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 76% !important;
}
.e-card-four-style-23 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-24 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-24 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-24 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 90% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-24 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-25 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: 0;
  left: 74%;
  z-index: 1;
  max-width: 160px;
  transform: translateX(-74%);
}
.e-card-four-style-25 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-25 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-25 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-26 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  bottom: 0;
  z-index: 1;
}
.e-card-four-style-26 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-26 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-26 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-27 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: 0;
  right: 0;
  z-index: 1;
  max-width: 160px;
}
.e-card-four-style-27 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-27 .e-card-item-wrap img{
  border-radius: 0px 100px;
}

.e-card-four-style-28 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-28 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-28 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-28 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-29 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-29 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-29 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-29 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-30 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-30 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-30 .e-card-item-wrap img{
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-30 .e-svg{
  background-image: url("../images/card-bg/Rectangle\ 386.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 255px;
}
.e-card-four-style-30 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-31 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 8px;
}
.e-card-four-style-31 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-31 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 73% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-31 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-32 p{
  box-shadow:none;
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-32 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-32 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 66% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-32 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.e-card-four-style-33 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-33 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-33 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 84% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-33 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-34 p{
  box-shadow: none;
  border-radius: 40px;
  bottom: -50px;
  z-index: 1;
}
.e-card-four-style-34 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-34 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 84% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-34 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-35 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-35 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-35 .e-card-item-wrap img{
  width: 94% !important;
  border: 6px solid #F64A7C;
  box-sizing: border-box;
  box-shadow: 16px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
}

.e-card-four-style-36 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: -50px;
  z-index: 1;
}
.e-card-four-style-36 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-36 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0);
  top: 50%;
  width: 65% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-36 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
  transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
}

.e-card-four-style-37 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-37 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-37 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 65% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-37 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.e-card-four-style-38 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-38 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-38 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-38 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-39 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-39 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-39 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 94% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-39 .e-svg{
  background-image: url("../images/card-bg/Rectangle\ 416.png");
  background-size: contain;
  background-position: center;
  top: 0;
  left: 0;
}
.e-card-four-style-39 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.e-card-four-style-40 .e-card-item-wrap img{
  border-radius: 4px;
  width: 100% !important;
}
.e-card-four-style-40 p{
  position: static;
  box-shadow:none;
  border-radius: 0px;
  z-index: 1;
  transform: translateY(0);
}
.e-section-bg-half{
	position: absolute;
	width: 100%;
	height: 60%;
	left: 0;
	bottom: 0px;
	display: inline-block;
	z-index: -1;
  opacity: .7;
}
.e-section-bg-half.e-buyofmomemt-bg{
  height: 50%;
  bottom: -1px;
  display: none;
  opacity: 1;
}
.e-collection-wrap .e-section-bg-half{
	bottom: -50px;
}

/*Six Column card list*/
.e-card-six-style-1 p{
	text-align: center;
	border-left: none !important;
	text-decoration: underline;
	color: #000000;
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;
	padding-top: 10px;
	cursor: pointer;
}
.e-card-six-style-2 img{
	/*border-radius: 100px 4px;*/
	width: 100%;
}
.e-card-six-style-2 p{
	background: #000000;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
	border-radius: 100px 0px;
	text-align: center;
	padding: 10px;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	margin-top: 10px;
}
.e-card-item-wrap img{
  width: 100%;
  border-radius: 0;
}






/*Recently viewed*/
.e-recent-viewed-wrap img{
	border-radius: 30px 0  30px 0;
}
.e-recent-viewed-wrap p{
	text-align: center;
	text-transform: uppercase;
	color: #000000;
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	margin-top: 10px;
}
.e-recent-viewed-wrap .slick-next, .e-image-viewer .slick-next, .e-favourite-stores .slick-next{
	width: 50px !important;
	height: 80px !important;
	border: none !important;
	background-color: red !important;
	right: 0px !important;
	top: 43% !important;
	border-radius: 5px;

	background-position: center !important;
	background-image: url("../images/icons/arrow-right.svg") !important;
	background-repeat: no-repeat !important;  
  background-size: cover !important;
}
.e-recent-viewed-wrap .slick-next:before, .e-image-viewer .slick-next:before, .e-favourite-stores .slick-next:before{
	display: none;
}
.e-recent-viewed-wrap .slick-prev, .e-image-viewer .slick-prev, .e-favourite-stores .slick-prev{
	width: 50px !important;
	height: 80px !important;
	border: none !important;
	background-color: red !important;
	right: 0px !important;
	top: 43% !important;
	border-radius: 5px;

	background-position: center !important;
	background-image: url("../images/icons/arrow-left.svg") !important;
	background-repeat: no-repeat !important;
  background-size: cover !important;
	z-index: 1 !important;
}
.e-recent-viewed-wrap .slick-prev:before, .e-image-viewer .slick-prev:before, .e-favourite-stores .slick-prev:before{
	display: none;
}
.e-recent-viewed-wrap .slick-prev.slick-disabled, .e-recent-viewed-wrap .slick-next.slick-disabled{
	display: none !important;
}
.e-image-viewer .slick-next{
  background-image: url("../images/icons/arrow-black-right.svg") !important;
}
.e-image-viewer .slick-prev{
  background-image: url("../images/icons/arrow-black-left.svg") !important;
}
.e-favourite-stores .slick-next{
  background-image: url("../images/icons/store-next-btn.svg") !important;
  width: 70px !important;
  right: -80px !important;
}
.e-favourite-stores .slick-prev{
  background-image: url("../images/icons/store-prev-btn.svg") !important;
  width: 70px !important;
  right: unset !important;
  left: -80px !important;
}
.e-image-viewer .slick-next, .e-image-viewer .slick-prev, .e-favourite-stores .slick-prev, .e-favourite-stores .slick-next{
  background-color: rgba(0, 0, 0, 0) !important;
  transition: 0.3s ease;
}
.e-image-viewer .slick-next:hover, .e-image-viewer .slick-prev:hover{
  background-color: rgba(255, 0, 0, 0.027) !important;
}



/*Testimonials*/
.e-testimonials-wrap  .slick-dots {
	bottom: -15px !important;
	text-align: right;
}
.e-testimonials-wrap .slick-dots li,.e-product-wrap .slick-dots li {
	margin: 0;
}
.e-testimonials-wrap .slick-dots li button:before{
	color: #CECECE;
	opacity: 1;
	font-size: 10px;
}
.e-testimonials-wrap .slick-dots li.slick-active button:before,.e-product-wrap .slick-dots li.slick-active button:before{
	color: #FF4A31;
	opacity: 1;
}
.e-testimonials-outer .e-section-bg-half{
	background: rgba(255, 74, 49, 0.1);
	height: 250px;
  bottom: -50px;
}
.e-testimonials-bg{
	background-position: center !important;
	background-image: url("../images/icons/testimonials-bg.svg") !important;
	background-repeat: no-repeat !important;
	display: inline-block;
	width: 50px;
	height: 50px;
	position: absolute;
	z-index: -1;
}
.e-testimonials-user{
	margin-top: 10px;
}
.e-testimonials-wrap p{
	padding: 30px 30px 20px;
	background: #fff;
	box-shadow: 4px 4px 20px rgba(255, 220, 215, 0.2);
	border-radius: 100px 0px;
	font-style: italic;
	font-size: 13px;
	line-height: 25px;
}
.e-testimonials-user img{
	width: 40px;
	height: 40px;
	border-radius: 100%;
	display: inline-block !important;
	margin-right: 15px;
}
.e-testimonials-user span{
	font-weight: 700;
	font-size: 14px;
	line-height: 17px;
	text-transform: capitalize;
	margin-right: 30px;
}
.e-testimonials-item{
	padding: 0 15px;
}
.e-testimonials-item p{
  min-height: 320px;
}
.e-testimonials-wrap .slick-prev, .e-testimonials-wrap .slick-next{
	display: none !important;
}

/*Subscription*/
.e-subscription-wrap{
	background: #000000;
	padding: 60px 0;
}
.e-subscription-wrap h6{
	color: #FFFFFF;
	font-weight: 800;
	font-size: 24px;
	line-height: 29px;
	letter-spacing: 0.1em;
	text-transform: uppercase;
	text-align: center;
}
.e-subscription-wrap p{
	color: #FFFFFF;
	font-weight: 300;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	opacity: .8;
}
.e-subscription-wrap{
	background-image: url("../images/subscription-bg.svg");
	background-repeat: no-repeat;
	background-position: 16% bottom !important;
}
.e-subscription-wrap input{
	background: transparent;
	color: #fff;
	border-radius: 16px 0px;
  border: 1px solid rgba(204, 204, 204, .6);
	box-sizing: border-box;
	padding: 12px;
	min-width: 420px;

	background-image: url("../images/icons/search.svg");
	background-size: 18px !important;
	background-position: 96% !important;
	background-repeat: no-repeat;
}
.e-subscription-wrap .e-header-search::placeholder{
  color: rgba(204, 204, 204, .6);
  font-weight: 500;
	font-size: 12px;
}
.e-subscription-input-wrap{
	display: inline-block;
}
.e-subscription-input-wrap .e-p-btn{
	position: absolute;
  right: 0;
  padding: 15.5px;
}
.e-subscription-wrap .e-p-btn{
  cursor: pointer;
}
.e-subscription-wrap .e-p-btn:hover{
  background: #92453a;
}
.e-subscription-wrap .e-p-btn.e-inactive{
  cursor: not-allowed;
}
.e-subscription-wrap .e-p-btn.e-inactive:hover{
  background: #FF4A31;
}

/*------------------------------------------------

Logo Strip Slider

-------------------------------------------------*/
.e-logo-slider-item img{
	width: 150px;
	height: 150px;
	border-radius: 100%;
	background: #fafafa;
	border: 5px solid #fff;
  box-shadow: 0px 3px 8px rgba(181, 175, 175, 0.25);
  object-fit: cover;
}

/*----------------Product----------------*/

/*Product banner slider*/

.e-product-wrap img {
	width: 100%;
	height: 500px;
  object-fit: contain;
}
.e-product-wrap .slick-dots {
	position: absolute;
	bottom: 20px;
	right: 13%;
	text-align: right;
}
.e-product-wrap .slick-dots li button:before{
	color: #CECECE;
	opacity: 1;
	font-size: 13px;
}
.e-product-accordion .accordion .card .btn-link:after {
  content: '';
  width: 7px;
  height: 2px;
  background-size: cover;
  text-align: center;
  right: 15px;
  top: 24px;
  position: absolute;
  color: #000;
  background-image: url(../images/online/minus.svg);

}
.e-product-accordion .accordion .card .btn-link.collapsed:after {
	width: 7px;
  height: 7px;
  background-size: cover;
  background-image: url(../images/online/plus.png);
}
.e-product-accordion .card-header {
   background-color: transparent;
   border-bottom: transparent;
}
.e-product-accordion .card {
	border: transparent;
	background-color: rgba(0, 0, 0, 0.05);
	border-radius: 4px;
}
.e-product-accordion .btn-link {
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;

	text-align: center;
	color: #000000;
	text-decoration: none;
}
.e-product-accordion a{
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	text-transform: uppercase;
	color: #000000;
	opacity: 0.77;
	cursor: pointer;
}
.e-product-accordion a.e-active{
  color: #FF4A31;
}
.e-product-accordion .card-body {
	padding: 0;
}
.e-product-accordion p {
	margin-bottom: 8px;
}
.e-prod-list-logo{
  width: 100%;
  height: 250px;
  object-fit: contain;
  background: #f1f1f1;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  padding: 5px;
  border-radius: 5px;
}
.e-prod-list-logo:hover{
  -webkit-transform: scale(1.025);
  -ms-transform: scale(1.025);
  transform: scale(1.025);
}
.e-local-productlist .e-prod-list-logo{
  padding: 0;
  background-color: #fff0;
}
.e-product-sort .css-2b097c-container {
	background: rgba(255, 255, 255, 0.05);
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-sizing: border-box;
	border-radius: 4px;

}
.e-product-sort  .css-1okebmr-indicatorSeparator {
	width: 0px;
}
.e-product-sort .css-1wa3eu0-placeholder {
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 15px;
	text-align: center;
	text-transform: capitalize;
	color: #000000;
}
.e-product-sort label{
  font-size: 12px;
  font-weight: 600;
  color: #505050;
}
 h6 {
 	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-transform: capitalize;
	color: #000000;
}
.e-p-btn.e-load-more {
	background: #FFFFFF;
	border: 1px solid #000000;
	box-sizing: border-box;
	border-radius: 16px 0px;
	color: #000;
	padding: 16px;
	font-weight: bold;
	font-size: 16px;
}
.e-p-btn.e-load-more:hover {
	color: #fff;
	background: #FF4A31;
}
.e-product-page-wrap{
  min-height: 600px;
}
.css-26l3qy-menu, .css-1uccc91-singleValue{
  font-size: 13px;
}





/* Search Page*/
.e-serach-section{
  background: #fbe1e1;
  background-image: url("../images/subscription-bg.svg");
  background-repeat: no-repeat;
  background-position: 16% bottom !important;
}
.e-serach-result{
  min-height: 500px;
}
.e-search-page-input-wrap{
  display: inline-block;
  width: 100%;
}
.e-search-page-input-wrap .e-header-search-btn{
  right: 0;
}
.e-search-page-tab-wrap{
  padding: 30px 0;
}
.e-search-page-tab-wrap h6{
  text-transform: uppercase;
  opacity: .5;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-search-page-tab-wrap h6.active{
  opacity: 1;
  border-bottom: 2px solid #FF4A31;
}
.e-search-page-tab-wrap h6:hover{
  opacity: .8;
}
.e-serach-cat-tag{
  font-size: 14px;
  box-shadow: 0px 3px 8px rgba(181, 175, 175, 0.25);
  padding: 15px;
  border-radius: 5px;
  color: #000;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-serach-cat-tag:hover{
  transform: scale(1.025);
}
.e-search-no-wrap .e-page-nothing{
  width: initial;
}
/*-------------contact.html-----------*/
.e-contact-banner {
	background-image: url(../images/online/contact-banner.png);
	height: 500px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
}
.e-contact-banner h1 {
	font-weight: 800;
	font-size: 48px;
	line-height: 59px;
	color: #FFFFFF;
	padding-top: 240px;
}
.e-contact-form h4 {
	font-weight: 800;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
}
.e-contact-form input,.e-contact-form textarea {
	width: 100%;
	background: #FFFFFF;
	border: 1px solid rgba(0, 0, 0, 0.25);
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 14px;
	/*text-transform: capitalize;*/
}
.e-contact-form input {
	padding: 5px 0px;
}
.e-contact-form input.e-content,.e-contact-form textarea.e-content {
	padding-left: 10px;
}
.e-contact-form textarea {
	resize: none;
}
.e-contact-form input.e-text {
	width: 90%;
}
.e-contact-form input.e-text-transform,.e-contact-form textarea.e-text-transform {
	text-transform: capitalize;
}
.e-contact-form label {
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	text-transform: uppercase;
	color: #000000;
}
.e-p-btn.e-contact-btn {
	padding: 14px 10px;
	border: none;
	cursor: pointer;
}
button:focus {
    outline: none!important;
}
/*.error {
  font-size: 14px;
  color: red;
  font-weight: 500;
}
.success {
	color: green;
	font-size: 14px;
	font-weight: 500;
}*/
.e-contact-form .e-error {
  border: 1px solid red;
  background-color: #f0554a;
  color: #75120b;
  font-size: 14px;
  font-weight: 500;
  padding: 15px;
}
.e-contact-form .e-alert-span {
  font-weight: 700;
}
.e-contact-form .success {
  color: green;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid green;
  padding: 15px;
  background-color: #6bed73;
}
.e-contact-form p.success span {
  font-weight: 700;
}


/*Video Player*/
.e-video-player{
  width: auto !important;
  height: 430px !important;
  text-align: center !important;
  background: #000000;
  object-fit: cover;
}
.e-hero-banner-wrap .e-video-player{
   height: 530px !important;
}
/*About*/

.e-about-hdr, .e-about-hdr-sm{
	font-size : 48px;
	line-height :58.51px;
	font-weight: 800;
	color: #000;

}
h4.e-about-content-head{
	font-weight: 800;
	font-size: 24px;
	line-height: 29px;
	text-transform: uppercase;
	color: #000000;
}
h4.e-about-content-sub-head {
	font-size: 18px;
	font-weight: 800;
}
.e-about-content{
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 32px;
	color: #606060;
}
.e-about h6{
	font-weight: 700;
	font-size: 14px!important;
}
.e-about-bnr{
	background-image: linear-gradient(#fff4 ,#fff4),  url('../images/about/2751001.png');
	background-repeat: no-repeat;
  background-position: center;
	padding-top: 150px;
  padding-bottom: 350px;
  /* height: 500px; */
	background-size: contain;
}
.e-about-img-container img{
  width: 100%;
}
.e-red-span, .e-contact-a{
	font-size: 14px;
	line-height: 19px;

}
.e-contact-a{
	color: #000000;
}
.e-red-span{
	color: #FF4A31;
}
/*--------------profile---------------*/
.e-profile-banner {
	background-image: url(../images/online/profile-banner.png);
	height: 284px;
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
}
.e-profile-banner h1 {
	font-weight: 800;
	font-size: 48px;
	line-height: 59px;
	color: #FFFFFF;
	padding-top: 120px;
}
.e-profile span.e-tabs-item.e-active {
  color: #000;
  font-weight: 800;
	font-size: 24px;
	line-height: 29px;
  cursor: pointer;
  border-bottom: 4px solid #FF4A31;
}
.e-profile span.e-color-inactive {
  font-weight: 800;
	font-size: 24px;
	line-height: 29px;
	color: #000000;
	opacity: 0.37;
	cursor: pointer;
}
.e-profile h5{
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	text-transform: capitalize;
}
.e-profile-detail-wrap {
	background: #FFFFFF;
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
.e-profile-content {
	padding: 20px 25px;
}
.e-profile-content p{
	font-size: 14px;
	line-height: 36px;
	color: #000000;
	font-weight: 800;
}
.e-profile-content p span{
	font-weight: 500;
}
.e-profile {
	padding-bottom: 150px;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  white-space: nowrap;
}
div.e-lg-scrollmenu{
  overflow: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  scrollbar-color: #444, #ffffff00
}
.e-lg-scrollmenu::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}
.e-lg-scrollmenu::-webkit-scrollbar-track {
  background-color: #ffffff00;
}
.e-lg-scrollmenu::-webkit-scrollbar-thumb {
  background-color: #444;
}
div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}
.e-filter-select{
  padding: 8px 15px;
  font-size: 12px;
  font-weight: 500;
  background: #e4e4e4;
  position: relative;
  top: -10px;
  border: 1px solid #717171;
  border-radius: 5px;
  cursor: pointer;
}
.e-product-dropdown{
  bottom: 0;
  position: fixed;
  height: 400px;
  width: 100%;
  left: 0;
  z-index: 999;
  background: #fff;
}
.e-selected-prod-wrap h6 span{
  color: red;
}
/*-------------Faq----------------*/
.e-faq-banner {
  background-image: url(../images/online/faq-banner.png);
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.e-faq-banner h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  color: #FFFFFF;
  padding-top: 240px;
}
.e-faq-form h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}

.e-faq-form .accordion .card {
  background: transparent;
  box-shadow: 0 0 0;
  border-radius: initial;
  border: transparent;
  transition: .5s ease;
  cursor: pointer;
}
.e-faq-form .accordion .card-header {
  padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: transparent;
  font-size: 16px;
  line-height: 28px;
}
.e-faq-form .accordion .card:hover {
  transform: none;
}
.e-faq-form .accordion .card-body {
  font-size: 14px;
  line-height: 28px;
  color: #606060;
  padding: 5px 8px 10px 0px;
}
.e-faq-form .accordion .card .btn-link.e-faq {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding-left: 0;
}
.e-faq-form .accordion .card .btn-link.e-faq:hover {
  opacity: 0.7;
}
.e-faq-form .accordion .card .btn-link:after {
  content: '';
  width: 16px;
  height: 16px;
  background-size: cover;
  text-align: center;
  right: 15px;
  top: 11px;
  position: absolute;
  color: #000;
  background-image: url(../images/online/minus-faq.png);

}
.e-faq-form .accordion .card .btn-link.collapsed:after {
  background-size: cover;
  background-image: url(../images/online/plus-faq.png);
}
/*----------career--------------------*/
.e-career-banner {
  background-image: url(../images/online/career-banner.png);
  height: 600px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.e-career-banner h1 {
  font-weight: 800;
  font-size: 48px;
  line-height: 59px;
  color: #FFFFFF;
  padding-top: 240px;
}
.e-career h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.e-career .accordion .card {
  background: #FBFBFB;
  box-shadow: 0 0 0;
  border-radius: 8px;
  border: transparent;
  transition: .5s ease;
  cursor: pointer;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}
.e-career .accordion .card-header {
  /*padding: 0;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: transparent;
  font-size: 16px;
  line-height: 28px;*/
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
/*.e-career .accordion .card:hover {
  transform: none;
}*/
.e-career .accordion .card-body {
  font-size: 14px;
  line-height: 28px;
  color: #606060;
  padding: 5px 8px 10px 0px;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  white-space: pre-wrap;

}
.e-career .accordion .card .btn-link {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
  color: #000;
  background-color: transparent;
  text-decoration: none;
  padding-left: 0;
}
/*.e-career .accordion .card .btn-link.e-faq:hover {
  opacity: 0.7;
}*/
.e-career .accordion .btn-link[aria-expanded="false"]{
  width: 100%;
  background-position: 99% 40%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;
  background-image: url(../images/online/plus-faq.png);

}
.e-career .accordion .btn-link[aria-expanded="true"]{
  width: 100%;
  background-position: 99% 40%;
  background-repeat: no-repeat;
  transition: ease-in-out 0.2s;
  background-image: url(../images/online/minus-faq.png);
}
/*.accordion .card .btn-link:after {
    content: '';
    width: 16px;
    height: 16px;
    background-size: cover;
    text-align: center;
    right: 15px;
    top: 24px;
    position: absolute;
    color: #000;
    background-image: url(../images/online/plus-faq.png);
}
.accordion .card .btn-link.collapsed:after {
    background-size: cover;
    background-image: url(../images/online/minus-faq.png);
}*/

.e-career .accordion .card .btn-link p {
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #000000;
  text-transform: capitalize;

}
.e-career .accordion .card .btn-link p.e-place{
  font-weight: 400!important;
  font-size: 16px;
  line-height: 28px;
  color: #FF4A31;
  text-transform: capitalize;
}
.e-career .accordion h6 {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.e-career-btn {
  border: 2px solid #FF4A31;
  padding: 12px 14px;
  position: relative;
  left: 19px;
}
.e-career-btn:hover {
  border: 2px solid #FF4A31;
  color: #FF4A31;
  position: relative;
  left: 19px;
  background: #fff;
}
/*------------Terms and conditions---------*/
.e-terms h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.e-terms p {
   text-align: justify;
   /*font-size: 14px;*/
}
.e-terms h5 {
  font-weight: 800;
  font-size: 18px;
  line-height: 29px;
  color: #000000;
  text-transform: capitalize;
}
.e-terms ol {
  padding-left: 13px;
}
.e-terms li {
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  color: #606060;
}
/*---------------Policy-------------------*/
.e-policy h5 {
  text-transform: uppercase!important;
}
.e-policy h5 span {
  text-transform: lowercase!important;
}
.e-policy ul {
  padding-left: 13px;
}
.e-policy .e-address {
  color: #000;
  font-weight: 500;
}
                       /*Local*/

/*----------------Product-detail-------------*/

.e-product-detail-tab span.e-tabs-item.e-active {
  font-weight: 800;
  font-size: 16px!important;
  color: #000000;
  cursor: pointer;
  line-height: 29px;
  padding-right: 30px;
}
.e-product-detail-tab span.e-color-inactive {
  font-weight: 800;
  font-size: 16px;
  line-height: 29px;
  color: #000000;
  opacity: 0.27;
  cursor: pointer;
  padding-right: 30px;
}
.e-border-bottom {
  border: .5px solid rgba(0, 0, 0, 0.1);
}
.e-tab-content-header {
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #606060;
}
.e-tab-content-paragraph {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #606060;
  margin-right: 100px;
}
.e-product-detail-tab ul {
   list-style: none;
   padding-left: 0px;
}

.e-product-detail-tab ul li:before {
  content: "•";
  font-size: 132%;
  padding-right: 5px;
}
.e-product-detail-tab p span {
  font-weight: 500;
  color: #606060;
}
.e-reated-products h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  color: #000000;
}
.e-reated-products h4::before {
  content: "";
  width: 15px;
  height: 16px;
  background: #FF4A31;
}
.e-before {
  position: absolute;
  left: 33.4%;
  top: 5px;
}
.e-after {
  position: absolute;
  left: 65%;
  top: 5px;
}
.e-reated-products h6 {
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.e-reated-products p {
  font-weight: 700;
  font-size: 12px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.77);
}
.e-reated-products p span {
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.47);
}
.e-product-detail h4 {
  font-weight: 800;
  font-size: 24px;
  line-height: 150%;
  color: #000000;
}
.e-product-detail p {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #000000;
}
.e-product-detail p span {
  font-weight: 800;
}
.e-price {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.77)!important;
}
.e-actual-price {
  font-weight: 400!important;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.47);
}
/*.e-size-wrap {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
  font-weight: 600!important;
}*/
/*.e-size-wrap:hover {
  background: #000;
  color: #fff;
  cursor: pointer;
}*/
.e-p-btn.e-contact-btn.e-offer-btn {
  padding: 18px 30px;
  font-size: 16px;
  border: 2px solid #FF4A31;
  font-weight: 800!important;
}
.e-p-btn.e-contact-btn.e-offer-btn:hover,.e-p-btn.e-contact-btn.e-ofer-btn:hover {
  border: 2px solid #FF4A31;
  color: #FF4A31;
  background: #fff;
  font-weight: 800!important;
}
.e-p-btn.e-contact-btn.e-favourite-btn {
  border: 2px solid #000;
  color: #000;
  background: #fff;
  padding: 18px 30px 18px 50px;
  font-weight: 800;
  font-size: 16px;
  position: absolute;
}
.e-local-product-detail .e-img-border:hover {
  border: 2px solid #FF6031;
}
.e-local-product-detail .e-img-border {
  border: 2px solid transparent;
}
.e-product-detail p span.e-product-color {
  font-weight: 500;
}
.e-full-width {
  width: 100%;
}
/*------------Best offer Modal--------*/

.e-best-ofer-modal .modal-content {
  border: transparent;
  background: #FFFFFF;
  border-radius: 50px 0px;
  padding: 20px 18px;
  overflow: hidden;
}
.e-best-ofer-modal .modal-header {
  border: transparent;
}
.e-best-ofer-modal .modal-header h6 {
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

}
.e-best-ofer-modal .close {
  float: right;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1;
  color: #000;
  text-shadow: transparent;
  opacity: 1;
}
.e-best-ofer-modal .modal-header .close {
  margin: -2rem -1rem 0rem auto;
  padding: 1rem 1rem 0;
}
.e-best-ofer-modal .modal-footer  {
  border: transparent;
}
.e-best-ofer-modal label {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
}
.e-best-ofer-modal input,.e-best-ofer-modal textarea {
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 5px 15px;
  font-size: 13px;
}
.e-best-ofer-modal input {
  width: 98%;
  height: 40px;
}
.e-location-modal input{
  width: 100%;
}
.e-best-ofer-modal textarea {
  width: 98%;
  resize: none;
}
.e-best-ofer-modal input.e-phone {
  width: 90%;
}
.e-p-btn.e-contact-btn.e-ofer-btn {
  padding: 18px 25px!important;
  border: 2px solid transparent;
}
/*-------------Local Profile-----------------*/
.e-local-profile h5 {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #000000;
}
.e-local-profile .e-profile-wrap {
  background-image: url(../images/local/profile-wrap.png);
  background-repeat: no-repeat;
  height: 210px;
}
.e-name {
  width: 130px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #FF4A31;
  border-radius: 50%;
  color: #fff;
  font-weight: 800;
  position: absolute;
  font-size: 48px;
}
.e-name-span {
  background:#F16522;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 13px;
  text-transform: uppercase;
}
.e-name-span:hover{
  text-decoration: none;
}
.e-profile-wrap h5 {
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
}
.e-profile-wrap p {
  font-weight: 400;
  font-size: 16px;
}
.e-local-profile-tab span.e-tabs-item,.e-local-profile-tab span.e-color-inactive  {
  font-weight: 700!important;
}
.e-local-profile-detail-wrap {
  background-image: url(../images/local/detail-wrap.png);
  background-repeat: no-repeat;
  height: 285px;
  background-color:  #FCFCFC;
  border: 1px solid #F2F2F2;
  background-position: 96% 78%;
}
.e-local-profile-content {
  padding: 30px;
}
.e-local-profile-content p {
  font-weight: 400;
  font-size: 14px;
  line-height: 45px;
  color: #000000!important;
}
.e-local-profile-content p span{
  font-weight: 500;
  color: #000000!important;
}
.e-local-profile-content .e-t-btn {
  font-weight: 800;
  font-size: 13px;
  color: #FF4A31;
  border: transparent;
  background: transparent;
  cursor: pointer;
  padding-left: 15px;
}
/*Mega header*/
.e-mega-header{
	position: unset !important;
}
.e-mega-header .dropdown-menu.show{
	transform: translate3d(0px, 34px, 0px);
	top: 12px;
	min-width: 80vw;
	box-shadow: 0 0 10px #00000011;
	position: absolute;
	left: 0px;
	will-change: transform;
}
.e-mega-header .dropdown-menu{
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	line-height: 18px;
	color: #606060;
}
.e-drop-down-scroll-wrapper{
  max-height: 75vh;
  overflow: auto;
}
.e-mega-header .e-dropdown-btn{
	min-width: 200px;
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	text-transform: uppercase;
	color: #000000;
	border: none;
	background-color: #00000000;
}
.e-mega-header .e-dropdown-btn:focus{
	box-shadow: none;
}
.e-mega-header span.e-header-btn{
	width: 100%;
	background-color: #ffffff;
	background-image: url('../images/icons/arrow-right-black.svg');
	background-repeat: no-repeat;
	background-position-y: center;
	background-position-x: 96%;
	transition: 0.3s ease;
	cursor: pointer;
}
.e-mega-header a, .e-mega-header a:hover{
  text-decoration: none;
  color: #606060 !important;
}
.e-mega-header span.e-header-btn.e-btn-on{
	background-color: #EDEDED;
}
.e-mega-header .e-mega-header-ad{
  max-width: unset;
	width: 100%;
  object-fit: cover;
}
.e-fav-close {
  position: absolute;
  right: 17px;
  top: 22px;
  cursor: pointer;
}
.e-fav-card .e-fav-close{
  top: 16px;
  right: 8px;
}

/*----------Change password-Profile-----------------*/

.e-login-modal.e-login-modal.e-change-password .e-left-banner {
  background-image: url('../images/online/password-banner.svg');
  height: 490px;
  border-radius: 0px 50px;
}
.e-best-ofer-modal.e-login-modal.e-change-password .modal-content {
  border-radius: 0px 50px;
}
.e-login-img.e-change-passwrd-img {
  top: 150px!important;
}
.e-login-modal.e-change-password .success{
  text-align: left!important;
}
.e-success-img {
  position: relative;
  top: 100px;
}
.e-login-modal.e-change-password .modal-dialog {
  max-width: 680px!important;
}
.e-login-img {
  position: relative;
  top: 107px;
}
.e-change-password.e-login-content-wrap {
  padding: 35px 0px 10px 2px;
}
.e-best-ofer-modal.e-login-modal.e-change-password h6 {
  padding: 0px;
  color: #000;
  font-weight: 800;
  font-size: 16px;
}
.e-best-ofer-modal.e-login-modal.e-change-password .modal-header {
  padding: 0;
}
.e-login-modal.e-change-password .e-login-content-wrap {
  padding: 35px 48px 10px 2px;
}
.e-best-ofer-modal.e-login-modal.e-change-password .modal-header .close {
  margin: -2rem -2rem 0rem auto!important;
}
.e-location-modal .modal-dialog{
  min-width: 1024px;
}

/*Login, sign up modal*/


.e-login-modal .e-left-banner {
  /* background-image: url('../images/online/login-banner.svg'); */
  background-image: linear-gradient(to bottom, #FF4A31 , #750FBB);
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
  min-height: 560px;
  height: 100%;
}
.e-best-ofer-modal.e-login-modal .modal-content{
  padding: 0!important
}
.e-best-ofer-modal.e-login-modal h6{
  font-weight: 800;
  font-size: 24px;
  color: #FFFFFF;
  padding: 50px 0px 0px 48px;
  letter-spacing: 1px;
}
.e-login-modal .modal-dialog {
  max-width: 730px!important;
}
.e-login-img {
  position: relative;
  top: 107px;
}
.e-login-content-wrap {
  padding: 35px 60px 10px 2px;
}
.e-login-modal label {
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  color: #000000;
}
.e-login-modal input:focus {
  border: 1px solid #000000;
  padding:0px 4px;
}
.e-best-ofer-modal.e-login-modal input {
  padding:0px 0px 0px 15px;
}
.e-t-btn,.e-login-modal  a.e-sign-up {
  font-weight: 600;
  font-size: 10px;
  text-transform: capitalize;
  color: #FF4A31;
  border: transparent;
  background: #fff;
  color: #FF4A31!important;
}
/*.e-login-modal  a.e-sign-up:hover {
  text-decoration: none;
}*/
.e-p-btn.e-contact-btn.e-ofer-btn.e-login-btn {
  width: 100%;
  font-weight: 700!important;
  font-size: 14px!important;
}
.e-p-btn.e-contact-btn.e-ofer-btn.e-login-btn:hover {
  background: #FF4A31;
  color: #fff;
  font-weight: 700!important;
}
.e-login-modal p {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  padding-top: 180px;
}
.e-login-modal p{
  padding-top: 90px;
}
.e-signup-modal p{
  padding-top: 290px;
}
 
.e-verify-otp p {
  padding-top: 290px;
}
.e-confirm p{
  padding-top: 6px;
}
.error {
  color: red;
  font-size: 12px;
}
.success {
  color: green;
  font-size: 12px;
  text-align: right!important;
}
.e-fav-card{
  padding-bottom: 185px;
}
.e-fav-card img.e-fav-card-img{
  width: 100%;
  height: 350px;
  object-fit: cover;
}
.e-fav-card .e-card-wrap {
  background: #fff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 445px;
  right: 0;
  top: 50%;
  /* height: 342px; */
  padding: 15px;
}
.e-fav-card .e-card-wrap h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
  margin-right: 7px;
}
e-fav-card .e-card-wrap h5 .e-vendor{
  font-size: 14px!important;
  font-weight: 400!important;
}
.e-fav-card .e-card-wrap p {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.e-fav-card .e-card-wrap span {
  font-weight: 700;
}
.e-fav-card .e-card-wrap a {
  text-decoration: underline;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #FF4A31;
}
.e-p-btn.e-contact-btn.e-view-store-btn {
  padding: 15px 38px!important;
}
.e-rating {
  font-weight: 400!important;
  font-size: 36px;
  line-height: 44px;
  color: rgba(0, 0, 0, 0.47);
}
.e-reviews {
  font-weight: 700!important;
  font-size: 10px!important;
  line-height: 12px;
}
/*About the store*/
.e-section-about h4 {
  font-weight: 800;
}
.e-section-about h6 {
  font-weight: 700;
  font-size: 14px;
}
.e-section-about p {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  color: #606060;
}
.e-section-about p a {
  cursor: pointer;
  text-decoration: underline;
  color: #FF4A31;
  margin-right: 25px;
}
.e-section-about p a:hover {
  text-decoration: underline;
}
.e-store-wrap {
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  box-sizing: border-box;
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px 0px 15px 10px;
}
.e-store-wrap h6 {
  font-weight: 500;
}
.e-store-wrap p {
  font-weight: 400;
  font-size: 14px;
}
.e-map-height {
  height: 255px;
}
/*--Wholesale best offer modal--*/
.e-offer-img {
  width: 100%;
  object-fit: none;
  cursor: pointer;
  height: 44vh
}
.e-best-ofer-modal.e-wholesale-modal .modal-content {
  border-radius: 0px 24px!important;
}
.e-best-ofer-modal.e-wholesale-modal .modal-dialog {
  min-width: 730px!important;
  max-height: 497px;
}
.e-modal-right-img {
  position: absolute;
  top: -74px;
  left: 12px;
}
.e-best-ofer-modal.e-wholesale-modal .modal-content {
  padding: 16px 18px!important;
}

.e-best-ofer-modal.e-wholesale-modal .modal-header .close {
  z-index: 1;
  position: relative;
  top: -39px;
  left: 31px;

}
.e-best-ofer-modal.e-wholesale-modal .modal-header .close span{
  background: #fff;
  border-radius: 50%;
  font-size: 24px;
  padding: 0px 4px;
  font-weight: 400;
}
/*------Enquiry form-----------*/
.e-enquiry-form-wrapper {
  background: #FFFFFF;
  box-shadow: 4px 4px 60px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 35px;
}
.e-enquiry-form-wrapper h5 {
  font-weight: 700;
  font-size: 16px;
}
.e-enquiry-form-wrapper label {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
}
.e-enquiry-form-wrapper input {
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 6px 12px;
}
.e-enquiry-form-wrapper input:focus {
  border: 1px solid rgba(0, 0, 0);
}
.e-enquiry-form-wrapper input::placeholder{
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.27)
}
.e-enquiry-form-wrapper p {
  font-size: 13px;
  font-weight: 300;
}
.e-p-btn.e-contact-btn.e-enquiry-btn {
  padding: 18px 24px;
}
/*Write a review modal*/
.e-p-btn.e-contact-btn.e-review-btn {
  background: #F7F7F7!important;
  border: 2px solid #FF4A31!important;
  box-sizing: border-box;
  border-radius: 16px 0px;
  font-weight: 700;
  font-size: 16px;
  color: #FF4A31;
  padding: 6px 20px!important;

}
.e-review-modal .css-2b097c-container {
  width: 63px;
  }
.e-review-modal .css-yk16xz-control, .e-review-modal .css-1pahdxg-control,.e-local-product-detail .css-yk16xz-control,.e-local-product-detail .css-1pahdxg-control{
  background: #E9E8E8;
  border: 1px solid #D6D6D6!important;
  box-sizing: border-box;
  border-radius: 4px;
  height: 6px;
}
.e-review-modal .css-1okebmr-indicatorSeparator,.e-local-product-detail .css-1okebmr-indicatorSeparator {
  width: 0px;
}
.e-review-modal .css-g1d714-ValueContainer,.e-review-modal .css-1uccc91-singleValue,.e-review-modal .css-1wa3eu0-placeholder,.e-review-modal .css-tlfecz-indicatorContainer,.e-local-product-detail .css-g1d714-ValueContainer,.e-local-product-detail .css-1uccc91-singleValue, .e-local-product-detail .css-1wa3eu0-placeholder,.e-local-product-detail .css-tlfecz-indicatorContainer{
  font-weight: 700;
  font-size: 14px;
  color: #000!important;
}
.e-review-modal p {
  font-size: 14px;
  font-weight: 400;
}
.slick-list{padding:0 20% 0 0;}
.e-thumbnail{
  max-width: 80%;
  border: 2px solid transparent;
  cursor: pointer;
}
.e-thumbnail:hover {
  border: 2px solid #FF6031;
}
.e-product-card a{
  color: unset;
}
.e-product-card a:hover{
  color: unset !important;
  text-decoration: none;
}
.e-local-productlist .e-filter-tab{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.e-local-productlist .e-filter-btn{
  background-image: url("../images/icons/+.svg");
	background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
}
.e-local-productlist .e-filter-btn.e-filter-active{
  background-image: url("../images/icons/-.svg");
	background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: right;
}
.e-product-filter{
  color: #484848;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
}
.e-product-filter svg{
  fill: #484848;
}
.e-range-selector .rc-slider-rail{
  background-color: #dddddd;
}
.e-range-selector .rc-slider-track{
  background-color:  #FF6031;
}
.e-range-selector .rc-slider-handle,.e-range-selector .rc-slider-handle:hover, .e-range-selector .rc-slider-handle-click-focused:focus, .e-range-selector .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
  border: 2px solid  #FF6031;
}
.e-range-selector .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging{
  box-shadow: 0 0 0 5px #ff613156;
}

.e-checkbox {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.e-checkbox label{
  margin: 1px 0 0 0;
}
/* Hide the browser's default checkbox */
.e-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color:#ffffff;
  border: 1px solid #cccccc;
}

/* On mouse-over, add a grey background color */
.e-checkbox:hover input ~ .checkmark {
  border: 1px solid #aaaaaa;
}

/* When the checkbox is checked, add a blue background */
.e-checkbox input:checked ~ .checkmark {
  background-color:#ffffff;
  border: 1px solid #cccccc;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.e-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.e-checkbox .checkmark:after {
  left: 6px;
  top: 6px;
  width: 8px;
  height: 8px;
  background-color: #FF6031;
  transform: translate(-4px, -4px);
}
.e-user-reviews .e-review-card{
  background: #FFFFFF;
  box-shadow: 0px 16px 32px rgba(221, 230, 237, 0.54);
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #606060;
}
.e-user-reviews .e-review-card .e-avatar{
  width: 40px;
  height: 40px;
  background: #F16522;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.e-user-reviews .e-review-card h5{
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
}
.e-user-reviews .e-review-card span.e-review-date{
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  color: #777676;
}
.e-user-reviews .e-review-card span.e-star-active{
  background-image: url("../images/icons/Star-active.png");
}
.e-user-reviews .e-review-card span.e-star-inactive{
  background-image: url("../images/icons/Star-inactive.png");
}
.e-user-reviews .e-review-card span.e-star-active, .e-user-reviews .e-review-card span.e-star-inactive{
  width: 12px;
  height: 12px;
  background-position: center;
  background-size: contain;
}
.e-store-rating{
  background-color: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
}
.e-store-rating .progress, .e-store-rating .progress-bar{
  height: 4px;
}
.e-fav-card .progress, .e-fav-card .progress-bar{
  height: 14px;
  clip-path: url(#svgPath);
}

.e-store-rating .progress, .e-fav-card .progress{
  background: #E6E6E6;
}
.e-store-rating .progress-bar, .e-fav-card .progress-bar{
  background: #FBCA1E;
}
.e-star{
  background-image: url("../images/icons/Star.png");
  background-position-y: center;
  background-repeat: no-repeat;
  background-position-x: right;
}
.e-store-rating .e-rating{
  font-weight: 800 !important;
  color: #444444;
}
.e-store-rating .e-review{
  font-size: 12px;
  line-height: 15px;
}

.e-outer-wrap {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  border: 2px solid #ffffff;
  cursor: pointer;
  -webkit-transition: 0.25s;
  transition: 0.25s;
}
.e-outer-wrap::after {
  content: ' ';
  background-image: url("../images/tick.svg");
  position: absolute;
  top: 22px;
  right: 28px;
  display: none;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
}
.e-location-modal .e-outer-wrap::after {
  top: 13px;
  right: 13px;
}
.e-outer-wrap.e-active::after {
  display: inline-block;
}
.e-outer-wrap.e-active{
  border: 2px solid #f0554a;
}
.e-location-modal .e-outer-wrap{ 
  padding: 12px;
  font-size: 12px;
}

.e-select-location-bg {
  background-image: url("../images/join-bg.svg");
  height: 95vh;
  width: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
}
.e-logout{
  opacity: 0.8;
}
.e-logout .e-t-btn{
  font-size: 14px;
}
.e-logout svg{
  width: 14px;
  height: auto;
  max-height: 16px;
  fill: #FF4A31;
}
.e-filter-modal .modal-content{
  border-radius: 0;
  min-height: 100vh;
}
.e-filter-modal .modal-dialog {
  min-width: 100vw;
  margin:0;
}
.e-mob-filter-btn{
  cursor: pointer;
}
.e-mob-filter-btn:hover{
  background-color: #EDEDED;
}
.e-mob-filter-btn.e-filter-on{
  background: rgba(0, 0, 0, 0.05);
}
.e-product-search .css-2b097c-container {
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.e-product-search .e-search-placeholder{
  background-image: url(../images/icons/feather_search.png);
  background-position-x: left;
  background-position-y: center;
  background-size: 16px;
  background-repeat: no-repeat;
  padding-left: 24px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.27);
}
.e-wholesale-query input::placeholder{
  font-weight: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.37);
}
.e-image-viewer .modal-dialog{
  min-width: 80vw;
}
.e-image-viewer .modal-content{
  max-height: 94vh;
}
.e-image-viewer .modal-body{
  overflow-y: auto;
}
.e-product-image-thumb{
  border: 2px solid #ffffff00;
  cursor: pointer;
  transition: 0.3s ease;
}
.e-product-image-thumb:hover{
  border: 2px solid #484848
}
.slick-current .e-product-image-thumb{
  border: 2px solid #FF6031;
}
.e-search-close{
  height: 40px;
  font-size: 34px;
  top: -6px;
  right: 8px;
  opacity: 0.6;
  font-weight: 300;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.e-search-close:hover{
  opacity: 1;
}
.e-google-btn button{
  width: 100%;
  color: #FF4A31 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  border:2px solid #FF4A31 !important;
  border-radius: 16px 0px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 16px !important;
  cursor: pointer;
  box-shadow: none!important;
}
.e-google-btn div{
  background: #FFFFFF00 !important;
  padding: 0 8px!important;
}
.e-google-btn span{
  color: #FF4A31 !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  padding: 0 8px !important;
}
.autocomplete-dropdown-container{
  width: 100%;
  background-color: #ffffff;
  z-index: 5;
}
.e-fav-tab-items span, .e-fav-tab-items span.e-tabs-item.e-active{
  font-size: 14px!important;
}
.e-fav-shop-logo{
  width: 90px;
  height: 90px;
  object-fit: contain;
  border-radius: 100px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-fav-card a{
  color: unset;
  text-decoration: none;
}
.e-fav-card a:hover{
  color: unset;
  text-decoration: none;
}
.e-search-location .css-1pahdxg-control, .e-search-location .css-1pahdxg-control:hover,.e-search-location .css-yk16xz-control{
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
}
.e-search-location .css-26l3qy-menu{
  opacity: 0;
  transition: 0.2s ease-in-out;
}
.e-search-location.e-show-options .css-26l3qy-menu{
  opacity: 1;
}
.e-fav-store-card{
  width: 100%;
}
.e-login-modal .e-t-btn{
  font-size: 12px;
}
.e-login-modal .e-sign-up .e-t-btn, p.e-sign-up{
  font-size: 10px;
}
.e-back .e-t-btn{
  background-image: url(../../assets/images/icons/left-arrow.svg);
  background-position: left;
  background-size: 10px;
  background-repeat: no-repeat;
  padding-left: 16px;
}
.e-pwa-header .e-favourite-icon, .e-pwa-header .e-user-icon{
  margin-top: 0;
}
.e-login-content-wrap .css-2b097c-container{
  font-size: 14px;
}
.e-add-fav-btn{
  width: 36px;
  height: 34px;
  bottom: 28px;
  left: 42px;
}
.e-fb-icon{
  background-image: url(../../assets/images/icons/facebook.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  width: 18px;
  height: 18px;
  background-color: #3b5999;
  border-radius: 40px;
  position: absolute;
  left: 18px;
}
.e-fb-btn .kep-login-facebook{
  width: 100%;
  color: #3b5999 !important;
  border:2px solid #3b5999 !important;
  background-color: #ffffff;
  border-radius: 16px 0px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 16px !important;
  cursor: pointer;
  box-shadow: none!important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 16px !important;
  text-transform: capitalize;
  position: relative;
  padding-left: 48px !important;
}
.e-wholesale-queries{
  padding: 42px 40px;
  box-shadow: 4px 4px 60px 0px #0000001A;
}
.e-wholesale-queries h5{
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
}
.e-wholesale-queries label{
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}
.e-wholesale-queries .e-qnt-label{
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}
.e-wholesale-price-heading{
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #000000;
}
.e-wholesale-subheading{
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  color: #000000;
  opacity: 0.67;
}
.e-image-viewer .slick-next,.e-image-viewer .slick-prev{
  width: 15px !important;
  height: 20px !important;
}
.e-cursor-pointer{
  cursor: pointer;
}
.e-about-illustration{
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
}
.e-contact-illustration{
  width: 70%;
  max-width: 300px;
}
/* ---Offers Popup---*/
.e-offer-popup .modal-content{
  background-color: #fff0;
  border: none;
}
.e-offer-popup .e-offer-popup-image{
  width: 100%;
  height: auto;
  object-fit: contain;
}
.e-offers-close-icon{
  top: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
}
.e-offers-close-icon circle{
  fill: #fff;
  transition: 0.35s ease-in;
}
.e-offers-close-icon:hover circle{
  fill: #ddd;
}
.e-offers-close-icon .e-tick-mark{
  fill: #111;
  transition: 0.35s ease-in;
}
.e-offers-close-icon:hover .e-tick-mark{
  fill: #ed4634;
}
.e-vendor-logo-wrap{
  padding-top: 100%;
}
.e-vendor-logo-wrap .e-vendor-list-logo{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.e-new-col-img{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  transition: width 0.3s ease, height 0.3s ease;
}

.e-new-col-img:hover{
  width: 105%;
  height: 105%;
}
.e-new-col-label{
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px;
  background: #000c;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  pointer-events: none;
}
.e-new-col-img-wrapper-3{
  width: 100%;
  padding-top: 200%;
  overflow: hidden;
}
.e-new-col-img-wrapper-3.e-new-col-wrapper-small-3{
  padding-top: calc(50% - 1rem);
  overflow: hidden;
}
.e-new-col-img-wrapper-4{
  width: 100%;
  padding-top: 80%;
  overflow: hidden;
}
.e-new-col-img-wrapper-4.e-new-col-wrapper-small-4{
  padding-top: 40%;
  overflow: hidden;
}
.e-new-col-img-wrapper-5{
  width: 100%;
  padding-top: 200%;
  overflow: hidden;
}
.e-new-col-img-wrapper-5.e-new-col-wrapper-small-5{
  padding-top: calc(50% - 1rem);
  overflow: hidden;
}
.e-new-col-img-wrapper-5.e-new-col-wrapper-med-5{
  padding-top: calc(100% - 0.5rem);
  overflow: hidden;
}
a.e-seller-link{
  transition: 0.3s ease-in;
  font-size: 12px;
  font-weight: bold;
  color: #F16522;
}
a.e-seller-link:hover{
  color: #f1390b;
  text-decoration: underline;
}
.e-product-thumb{
  max-width: 140px!important;
}
.e-view-more-btn{
  background-color: #f1390b;
  color: #fff;
  padding: 10px 20px;;
  font-weight: 600;
  border: none;
  border-radius: 8px;;
}